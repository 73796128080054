<template>
	<div class="container">
		<div class="search" style="display:flex">
			<el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
				<el-form-item>
					<el-input @keyup.enter="Search('condition')" v-model.trim="search.uid" placeholder="用户标识"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select clearable v-model="search.type" placeholder="消息类型">
						<el-option v-for="item in messageType" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="date1" :clearable="false" @blur="focusEvent1" type="datetime" placeholder="开始时间" :shortcuts="shortcuts" @change="selectTime">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="date2" :clearable="false" @blur="focusEvent2" type="datetime" placeholder="结束时间" :shortcuts="shortcuts" @change="selectTime1">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="Search('condition')">查询</el-button>
				</el-form-item>

			</el-form>
			<!-- <div>
        <el-button type="primary" size="small" @click="addSysmessage">新建</el-button>
      </div> -->
		</div>
		<!-- 列表 -->
		<el-table border v-bind:data="list" :empty-text="emptyText">
			<el-table-column label="用户账号" width="220px">
				<template v-slot="scope">
					<span>{{ JSON.parse(scope.row.extended_info).account }}</span>
				</template>
			</el-table-column>
			<el-table-column label="uid" width="200px">
				<template v-slot="scope">
					<span>{{ scope.row.uid }}</span>
				</template>
			</el-table-column>
			<el-table-column label="消息类型" width="130px">
				<template v-slot="scope" >
					<span>{{ changeType(scope.row.type) }}</span>
				</template>
			</el-table-column>
			
			<el-table-column label="消息内容" :show-overflow-tooltip="true">
				<template v-slot="scope">
					{{ JSON.parse(scope.row.extended_info)}}
				</template>
			</el-table-column>
			
			
			<el-table-column label="消息查看状态"  width="80px">
				<template v-slot="scope">
					<span>{{ scope.row.status==0?'未查看':scope.row.status==1?'已查看': '未知' }}</span>
				</template>
			</el-table-column>
			<el-table-column label="消息通知时间"  width="220px">
				<template v-slot="scope">
					<span>{{ changeTime(scope.row.time) }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="deleteSysmessage(scope.row)">删除</el-button>
        </template>
      </el-table-column> -->
		</el-table>
		<div class="page">
			<el-button type="text" :disabled="predisabled" @click="prePage" size="mini">上一页</el-button>
			<el-button type="text" :disabled="nextdisabled" @click="nextPage" size="mini">下一页</el-button>
		</div>

		<el-dialog title="新增系统消息" v-model="dialogFormVisible" width="600px">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="额外数据" prop="extended_info" label-width="110px">
					<el-input type="textarea" rows="5" placeholder="Json数据" v-model="form.extended_info" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="终端" prop="schema" label-width="110px">
					<el-input v-model="form.schema" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="系统消息类型" prop="type" label-width="110px">
					<el-select v-model="form.type">
						<el-option v-for="item in messageType" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户编号" prop="uid" label-width="110px">
					<el-input v-model="form.uid" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" @click="Validate(addconfirm)">新增</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
</template>

<script type="text/ecmascript-6">
	import util from '../../../util/util'
	export default {
		name: 'sysmessage',
		mixins: [],
		components: {},
		props: {},
		data() {
			return {
				shortcuts: [{
					text: '今天',
					value: new Date(),
				}],
				messageType: [{
						value: '系统通知消息',
						key: 1
					},
					{
						value: '分享设备',//'用户分享设备通知被分享者消息',
						key: 2
					},
					{
						value: '分享设备反馈',//'用户反馈分享状态通知分享者结果消息',
						key: 3
					},
					{
						value: '主人移除分享',//'被分享者，被设备主人删除与设备绑定状态时，通知被分享者',
						key: 4
					},
					{
						value: '分享者移除分享',//'被分享者主动移除被分享的设备，通知设备主人',
						key: 5
					},
					{
						value: '订单完成',//'订单完成通知消息',
						key: 6
					},
					{
						value: '反馈处理',//'后台处理用户反馈状态通知消息',
						key: 7
					},
					{
						value: '订阅续费',//'订阅续期通知消息',
						key: 11
					},
					{
						value: '取消订阅',//'取消订阅通知消息',
						key: 12
					},
					{
						value: '订阅到期',//'订阅到期通知消息',
						key: 13
					},
					{
						value: '活动消息',//'后台推送系统活动消息',
						key: 14
					},
					{
						value: '质保审核',//'质保申请审核通知消息',
						key: 15
					},
					{
						value: '试用审核',//'免费试用申请审核通知消息',
						key: 16
					},
					{
						value: '网关被绑定',//'设备已被其他网关用户绑定通知消息',
						key: 17
					},
					{
						value: '低电量',//'设备低电量通知消息',
						key: 18
					},
					{
						value: '免费激活云存储',//'免费激活',
						key: 19
					},
					{
						value: '异地登录',
						key: 20
					},
				],
				search: {
					action: 2,
					last_id: '',
					start_time: '',
					end_time: '',
					type: '',
					uid: '',
					rows: 10
				},
				list: [],
				total: 0,
				predisabled: false,
				nextdisabled: false,
				date1: '',
				date2: '',
				form: {
					extended_info: '',
					schema: '',
					type: '',
					uid: '',
				},
				dialogFormVisible: false,
				rules: {
					extended_info: [{
						required: true,
						message: '请输入额外数据',
						trigger: 'blur'
					}],
					schema: [{
						required: true,
						message: '请输入终端',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请输入系统消息类型',
						trigger: 'blur'
					}],
					uid: [{
						required: true,
						message: '请输入用户编号',
						trigger: 'blur'
					}],
				},
				emptyText: '请按条件查询'
			}
		},
		computed: {},
		watch: {},
		methods: {
			addSysmessage() {
				this.dialogFormVisible = true
			},
			Validate: function(call) {
				this.$refs['form'].validate(res => {
					if (res && call) {
						call()
					}
				})
			},
			addconfirm() {
				this.api.SysMessageCreate(this.form).then(res => {
					if (res.data.code == 200) {
						this.$message.success('新增成功')
						return
					}
					this.$message.error('新增失败' + res.data.msg)
				})
			},
			LookMsg(item) {
				this.api.SysMessageInfo({
					id: item.id,
					uid: item.uid,
				}).then(res => {
					if (res.data.code == 200) {
						this.form = res.data.data.data
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			focusEvent1() {
				if (!this.date1) {
					this.search.start_time = ''
				}
			},
			focusEvent2() {
				if (!this.date2) {
					this.search.end_time = ''
				}
			},
			selectTime() {
				if (this.date1) {
					if (this.search.start_time == this.date1.getTime() / 1000) {
						return
					}
					this.search.start_time = this.date1.getTime() / 1000
				} else {
					this.search.start_time = ''
				}
			},
			selectTime1() {
				if (this.date2) {
					if (this.search.end_time == this.date2.getTime() / 1000) {
						return
					}
					this.search.end_time = this.date2.getTime() / 1000
				} else {
					this.search.end_time = ''
				}
			},
			changeTime(time) {
				return util.dateFormat(time)
			},
			prePage() {
				this.search.action = 1
				this.search.last_id = this.list[0].id
				this.Search('page')
			},
			nextPage() {
				this.search.action = 2
				this.search.last_id = this.list[this.list.length - 1].id
				this.Search('page')
			},
			Search(item) {
				if (!this.search.start_time && !this.search.end_time && !this.search.type && !this.search.uid) {
					this.$message.error('请输入查询条件!')
					return
				}
				if (item == 'condition') {
					this.predisabled = false
					this.nextdisabled = false
					this.search.last_id = ''
				}
				this.api.SysMessageList({
					action: this.search.action,
					last_id: this.search.last_id,
					start_time: this.search.start_time,
					end_time: this.search.end_time,
					type: this.search.type,
					uid: this.search.uid,
					rows: this.search.rows
				}).then(res => {
					if (res.data.code == 200) {
						if (res.data.data.data && res.data.data.data.length) {
							if (this.search.action == 1) {
								this.nextdisabled = false
							} else {
								this.predisabled = false
							}
							this.list = res.data.data.data
						} else if (item == 'condition' && ((res.data.data.data && !res.data.data.data.length) || !res.data.data.data)) {
							this.list = []
							this.emptyText = 'No Data'
							this.predisabled = true
							this.nextdisabled = true
						} else if (item == 'page' && ((res.data.data.data && !res.data.data.data.length) || !res.data.data.data)) {
							if (this.search.action == 1) {
								this.predisabled = true
							} else {
								this.nextdisabled = true
							}
						}
						if (res.data.data.data && res.data.data.data.length == 0) {
							this.emptyText = 'No Data'
						}
						// this.list = res.data.data.data
						// this.total = res.data.data.page_info.total
						return
					}
					this.emptyText = 'No Data'
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			deleteSysmessage(item) {
				this.$confirm('是否删除该系统消息?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 删除请求
					this.api.SysMessageDelete({
						schema: item.schema,
						uid: item.uid,
					}).then(res => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							})
							this.Search()
							return
						}
						this.$message.error('删除失败' + res.data.msg)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消删除'
					});
				})
			},
			changeType(item) {
				return util.handleValueDisplay(this.messageType, item)
			},
		},
		filters: {},
		mounted() {
			// this.Search()
		},
		created() {}
	}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
	.page {
		float: right;
		margin-top: 10px
	}
</style>